<priva-dialog
    class="feedback-dialog__dialog"
    type="dynamic"
    alignment="center"
    [title]="titleKey() | translate"
    [subTitle]="subTitleKey() | translate"
    [buttons]="buttons"
    (buttonClick)="close()"
    (close)="close()"
    (backdropClick)="onBackdropClick($event)"
>
    <p>{{ textKey() | translate: textParams() }}</p>
</priva-dialog>
